import * as React from "react"

const imgWrapperStyle = {
    display: "flex",
    width: "100%",
    height: "100%",
}

const imgTitleStyle = {
    position: "absolute",
    background: "white",
    padding: "2px",
    margin: "2px 0 0 2px",
}

const pStyle = {
    margin : "0"
}

const imgStyle = {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    margin: "auto",
}

const dotStyle = {
    cursor: "pointer",
    height: "15px",
    width: "15px",
    margin: "0 2px",
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    transition: "background-color 0.6s ease",
}

const activeDotStyle = {
    backgroundColor: "#717171",
}

const dotWrapperStyle = {
    position: "absolute",
    bottom: "0",
    width: "100%",
    textAlign: "center",
}

const galleryWrapperStyle = {
    width: "80vw",
    height: "45vw",
    margin: "auto",
    position: "relative",
    backgroundColor: "lightgrey",
}

const buttonContainerStyle = {
    position: "absolute",
    height: "100%",
    top: "0",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
}

class ImageGallery extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            active: 0,
            len: props.value.length,
            p: props.value,
        }
        
        this.handlePrev = this.handlePrev.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.dotClick = this.dotClick.bind(this);
    }

    DrawImage(prop){
        return (
            <div style={imgWrapperStyle}>
                <div style={imgTitleStyle}><p style={pStyle}>{((prop.title != null) ? prop.title : prop.alt)}</p></div>
                <img style={imgStyle} src={prop.img} alt={prop.alt}/>
            </div>           
        )
    }
    
    DrawDots(num_dots, active_dot){
        var dots = []
    
        for(let i = 0; i < num_dots; i++){
            if(i === active_dot){
                dots.push(
                    <div role="button" style={{...dotStyle, ...activeDotStyle}} onClick={(e) => {this.dotClick(e, i)}} onKeyDown={(e) => {this.dotClick(e, i)}} tabIndex={0} aria-label="Active Image"/>
                )
            }else{
                let ar = "Go to image " + i.toString();
                dots.push(   
                    <div role="button" style={dotStyle} onClick={(e) => {this.dotClick(e, i)}} onKeyDown={(e) => {this.dotClick(e, i)}} tabIndex={0} aria-label={ar}/>
                )
            }
        }
        return (
            <div style={dotWrapperStyle}>
                <div style={{margin: "auto"}}>
                    {dots}
                </div>
            </div>
        )
    }

    dotClick(e, i){
        // console.log("Dot click: " + i);
        this.setState({active: i});
    }

    handlePrev(e) {
        var m = this.state.len - 1;
        var a = this.state.active;
        var n = -1;
        if(a === 0){
            n = m;
        }else{
            n = a - 1;
        }
        this.setState({active: n})
    }
    
    handleNext(e) {
        var m = this.state.len - 1;
        var a = this.state.active;
        var n = -1;
        if (a === m){
            n = 0;
        }else{
            n = a + 1;
        }
        this.setState({active: n})
    }

    render() {
        return (
            <div style={galleryWrapperStyle}>
                {this.DrawImage(this.state.p[this.state.active])}
                {this.DrawDots(this.state.len, this.state.active)}
                <div role="button" style={{...buttonContainerStyle, left:"0"}} onClick={this.handlePrev} onKeyDown={this.handlePrev} tabIndex={0}>
                    <div style={{backgroundColor:"white", padding:"2px", border: "1px solid black"}}>Prev</div>
                </div>
                <div role="button" style={{...buttonContainerStyle, right:"0"}} onClick={this.handleNext} onKeyDown={this.handleNext} tabIndex={0}>
                    <div style={{backgroundColor:"white", padding:"2px", border: "1px solid black"}}>Next</div>
                </div>
            </div>
        )
    }
}

export default ImageGallery